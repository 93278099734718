import { Environments } from './snowplow.enums';

const SNOWPLOW_QA_ENDPOINT = 'https://snowplow-collector-f4ewayaueq-ts.a.run.app';
const SNOWPLOW_PRODUCTION_ENDPOINT = 'https://snowplow-collector-gebjphxznq-ts.a.run.app';

export const SNOWPLOW_COLLECTION_ENDPOINTS: Record<Environments, string> = {
  [Environments.test]: SNOWPLOW_QA_ENDPOINT,
  [Environments.development]: SNOWPLOW_QA_ENDPOINT,
  [Environments.staging]: SNOWPLOW_QA_ENDPOINT,
  [Environments.prod]: SNOWPLOW_PRODUCTION_ENDPOINT,
  [Environments.production]: SNOWPLOW_PRODUCTION_ENDPOINT
} as const;

export const DEBOUNCE_TIME = 500; // milliseconds
