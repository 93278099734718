import {
  ButtonClickTrackingPlugin,
  enableButtonClickTracking
} from '@montugroup/montu-browser-plugin-button-click-tracking';
import { LinkClickTrackingPlugin, enableLinkClickTracking } from '@montugroup/montu-browser-plugin-link-click-tracking';
import type { BrowserTracker, SelfDescribingJson } from '@snowplow/browser-tracker';
import { enableActivityTracking, newTracker, setUserId, trackPageView } from '@snowplow/browser-tracker';
import type { MutableRefObject } from 'react';
import { DEBOUNCE_TIME, SNOWPLOW_COLLECTION_ENDPOINTS } from './snowplow.constants';
import { Environments } from './snowplow.enums';

/**
 * Determines the current environment.
 * @returns The current environment.
 */
export function getEnvironment(): Environments {
  const envValue = import.meta.env?.MODE || process.env.NODE_ENV || Environments.development;
  return envValue in Environments ? (envValue as Environments) : Environments.development;
}

/**
 * Initializes a Snowplow tracker and enables tracking features.
 * @param tracker - Reference to store the BrowserTracker instance.
 * @param appId - Application ID is used to distinguish different applications that are being tracked by the same Snowplow stack
 * @param trackerName - Tracker namespace
 * @param userId - Unique ID set by business
 *
 * Tracking features enabled:
 * - Button click tracking: Tracks user interactions with buttons.
 * - Link click tracking: Monitors clicks on links within the application.
 * - Activity tracking: Monitors user activity on the page e.g. is the tab in focus, does the mouse move over the page, does the user scroll, is updatePageActivity called, etc.
 */
export const initializeSnowplow = (
  tracker: MutableRefObject<BrowserTracker | null>,
  appId: string,
  trackerName: string,
  userId: string | undefined
): void => {
  try {
    const snowplowEndpoint = SNOWPLOW_COLLECTION_ENDPOINTS[getEnvironment()];
    tracker.current = newTracker(trackerName, snowplowEndpoint, {
      appId,
      plugins: [ButtonClickTrackingPlugin(), LinkClickTrackingPlugin()]
    });
    setUserId(userId);
    enableButtonClickTracking();
    enableLinkClickTracking();
    enableActivityTracking({
      minimumVisitLength: 10,
      heartbeatDelay: 10
    });
  } catch {
    console.error('Error initializing Snowplow tracker');
  }
};

/**
 * Tracks pageviews on route changes with debounce protection.
 * @param tracker - Reference to the BrowserTracker object.
 * @param lastTrackedTime - Reference to store the last tracked pageview timestamp.
 *
 * Debounce protection:
 * Prevents tracking multiple pageviews in quick succession by enforcing a minimum
 * time interval {@link DEBOUNCE_TIME} between tracked pageviews. This is useful for
 * handling rapid navigation or route changes without generating duplicate data.
 */
export const trackPageViewWithDebounce = (
  tracker: MutableRefObject<BrowserTracker | null>,
  pageViewContext: SelfDescribingJson<Record<string, unknown>>[] | undefined,
  lastTrackedTime: MutableRefObject<number>
): void => {
  try {
    const currentTime = Date.now();
    if (tracker.current && currentTime - lastTrackedTime.current > DEBOUNCE_TIME) {
      lastTrackedTime.current = currentTime;
      trackPageView({
        context: pageViewContext
      });
    } else {
      return;
    }
  } catch (error) {
    console.error('Error tracking pageview', error);
  }
};
