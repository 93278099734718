import type { FC, ReactElement } from 'react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import type { BrowserTracker, Props } from './snowplow.types';
import { initializeSnowplow, trackPageViewWithDebounce } from './utils';
/**
 * Snowplow component for initializing and managing Snowplow tracking.
 * @param userId - Unique ID set by business
 * @param appId - Application ID is used to distinguish different applications that are being tracked by the same Snowplow stack
 * @param trackerName - Tracker namespace
 * @param children - Child components to render.
 */
export const Snowplow: FC<Props> = ({ userId, appId, trackerName, pageViewContext, children }) => {
  const tracker = useRef<BrowserTracker | null>(null);
  const lastTrackedTime = useRef<number>(0);
  const location = useLocation(); // Get current location

  useEffect(() => {
    if (!tracker.current) {
      initializeSnowplow(tracker, appId, trackerName, userId);
    }
  }, []);

  useEffect(() => {
    trackPageViewWithDebounce(tracker, pageViewContext, lastTrackedTime);
  }, [location.pathname]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

/**
 * Helper function for conditional Snowplow wrapping.
 * @param component - React component to potentially wrap with Snowplow.
 * @param snowPlowProps - Props for the Snowplow component (excluding 'children').
 * @param enabled - Boolean flag to determine if Snowplow should be enabled.
 * @returns The component wrapped with Snowplow if enabled, otherwise the original component.
 *
 * This function allows for conditional application of Snowplow tracking based on
 * a feature flag or other runtime conditions. It's useful for gradually rolling out
 * Snowplow tracking or enabling/disabling it in different environments.
 */
export const WithSnowplow = (component: ReactElement, snowPlowProps: Omit<Props, 'children'>, enabled: boolean) => {
  if (enabled) {
    return <Snowplow {...snowPlowProps}>{component}</Snowplow>;
  }
  return component;
};

export default Snowplow;
